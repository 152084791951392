import React from 'react'
import {
  Card,
  GridWrapper,
  GridItem,
  FilledButton,
  Display1,
} from '@jsluna/react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Hero from '../components/Hero'
import './index.scss'
import ellieSimmondsImage from '../images/Sains_AK_EllieSimmonds.jpg'
import holidayClubs from '../images/Mask.jpg'
import routes from '../config/routes'
import config from '../config'

const { isSessionOpen, covid } = config

const IndexPage = () => (
  <Layout testid="home-page">
    <div className="home__wrapper">
      <SEO title="Home" />
      <Hero home />
      <GridWrapper matrix className="mt2">
        <GridItem size="1" className="activity_wrapper">
          <GridWrapper
            matrix
            equalHeight
            className=""
            verticalAlign="center"
            horizontalAlign="center"
          >
            {!isSessionOpen && !covid && (
              <>
                <h2>
                  Sainsbury’s Active Kids holiday clubs are over for the summer!
                </h2>
                <h5>
                  Thank you so much to everyone who joined us this summer, we
                  hope your kids had a brilliant and active summer this year!
                </h5>
                <div>
                  <Link to={routes.contactForm} className="info_graphic_cta">
                    <FilledButton
                      fullWidth
                      type="button"
                      className="info_graphic_cta"
                    >
                      Keep in touch
                    </FilledButton>
                  </Link>
                </div>
              </>
            )}
            {isSessionOpen && !covid && (
              <>
                <h2>How do I book a Sainsbury’s Active Kids holiday club?</h2>
                <GridItem size={{ md: '1/3' }}>
                  <Card className="find_club">
                    <Display1
                      element="h3"
                      className="ln-u-flush-sides ln-u-text-align-center"
                    >
                      1. Find a club near you
                    </Display1>
                    <p>
                      Enter your postcode to find availability at a local
                      holiday club
                    </p>
                  </Card>
                </GridItem>
                <GridItem size={{ md: '1/3' }}>
                  <Card className="book_pay">
                    <Display1
                      element="h3"
                      className="ln-u-flush-sides ln-u-text-align-center"
                    >
                      2. Book and pay
                    </Display1>
                    <p>
                      Book as many days as you like over summer, for up to 5
                      kids at a time. £10 per child per day.
                    </p>
                  </Card>
                </GridItem>
                <div>
                  <Link to={routes.venues} className="info_graphic_cta">
                    <FilledButton
                      fullWidth
                      type="button"
                      className="info_graphic_cta"
                    >
                      Book now
                    </FilledButton>
                  </Link>
                </div>
              </>
            )}
            {covid && (
              <>
                <h2>Sainsbury’s Active Kids Holiday Clubs</h2>
                <h5>
                  As you know, Sainsbury’s took the decision to postpone Active
                  Kids Summer Holiday Clubs this year as a result of the
                  coronavirus pandemic. Safety is their highest priority and we
                  thank you again for your support and understanding. In place
                  of the Holiday Clubs, Sainsbury’s introduced their virtual
                  Active 20 Challenge, a twenty-day free-to-access virtual
                  summer club for children aged 5-15.
                </h5>

                <h5>
                  <>
                    {`Sainsbury’s continually review their services and have
                    decided not to reintroduce Active Kids Summer Holiday Clubs
                    next year. Helping customers live healthy lives remains
                    important to Sainsbury’s and they will continue to support
                    parents with ways to keep their children healthy and happy.
                    You can find further inspiration on `}
                  </>
                  <a href="https://sainsburys.co.uk">Sainsburys.co.uk</a>
                  <> and </>
                  <a href="https://argos.co.uk">Argos.co.uk</a>
                </h5>
              </>
            )}
          </GridWrapper>
        </GridItem>
        <GridWrapper matrix className="mt2 video_wrapper">
          <GridItem size="1">
            <Card className="video_container">
              <iframe
                title="Active kids"
                src="//www.youtube-nocookie.com/embed/dCANMs-o-mI?controls=1&modestbranding=1"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="video"
              />
            </Card>
          </GridItem>
          <GridItem size="1">
            <Card className="video_description">
              <h4>
                In the summer of 2019, we ran 70 Sainsbury’s Active Kids holiday
                clubs across the country, offering over 112,000 days of
                activities! Our active kids took part in a varied range of
                activities throughout the day, learning new skills and meeting
                new people
              </h4>
            </Card>
          </GridItem>
        </GridWrapper>
        <div className="holiday_clubs">
          <img
            src={holidayClubs}
            alt="Sainsbury’s Active Kids holiday clubs are designed to help children lead healthier lives"
          />
        </div>
        <GridItem size={{ md: '1/2' }}>
          <Card className="holiday_clubs_text">
            <h2>What are Active Kids holiday clubs?</h2>
            <div>
              <p>
                Sainsbury’s Active Kids holiday clubs are designed to help
                children to be more active and lead healthier lives in the
                summer holidays, at a great price for parents
              </p>
              <p>
                We want our holiday clubs to be fun and inclusive for children
                of all abilities, so our Active Kids will be trying all sorts of
                activities including sports such as tennis, gymnastics and
                dodgeball, alongside arts and crafts and even circus skills! Our
                activity professionals are trained in inclusion and, wherever
                possible, support activities for children of all abilities. Kids
                will be grouped by age, helping to build their confidence with
                new people and new activities. All of this is only £10 per child
                per day, and includes a healthy lunch and snacks.
              </p>
            </div>
          </Card>
        </GridItem>
        <GridItem size="1">
          <GridWrapper
            matrix
            className=""
            verticalAlign="center"
            horizontalAlign="center"
          >
            <GridItem size={{ md: '1/2' }}>
              <Card className="commitment_text">
                <h2>Our commitment</h2>
                <div>
                  <p>
                    From 2005 to 2017 you collected vouchers that helped
                    Sainsbury’s donate over £186 million worth of equipment and
                    experiences to UK schools and clubs.
                  </p>
                  <p>
                    In 2018, we took a fresh look at how we could help keep kids
                    active and launched our Sainsbury’s Active Kids holiday
                    clubs in place of our voucher scheme. We want to concentrate
                    on the areas where we can make the biggest difference, and
                    with parents telling us it’s hard to keep children active
                    during the holidays, we believe our Active Kids holiday
                    clubs can do more to help.
                  </p>
                  <p>
                    In 2019 we expanded our trial to 70 locations across England
                    and Wales as part of our ongoing commitment to keeping kids
                    healthy and active. Over 100,000 children took part in 2019,
                    enjoying a wide range of activities from fencing and circus
                    skills to tennis and quick cricket.
                  </p>
                  {isSessionOpen && (
                    <p>
                      We’ve expanded to 150 locations this year, including
                      Scotland as well as England and Wales. Our clubs will run
                      from 27th July to 28th August and will continue to help
                      more children stay active over the summer holidays.
                    </p>
                  )}
                </div>
              </Card>
            </GridItem>
            <GridItem size={{ md: '1/2' }}>
              <div className="ellie_simmonds_bg">
                <img
                  src={ellieSimmondsImage}
                  alt="Ellie Simmonds, OBE - British Paralympian swimmer, Active Kids Ambassador"
                />
                <div className="ellie_simmonds_intro">
                  <h4>Ellie Simmonds, OBE - British Paralympian swimmer</h4>
                  <em className="active_kids_ambassador">
                    Active Kids Ambassador
                  </em>
                </div>
              </div>
            </GridItem>
          </GridWrapper>
        </GridItem>
      </GridWrapper>
    </div>
  </Layout>
)

export default IndexPage
